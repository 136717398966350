@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-[#181818];
}

.btn-primary {
  @apply bg-primary py-2 rounded-lg text-black text-lg w-full px-4;
  @apply disabled:opacity-40;
}

.menu-item {
  color: rgba(255, 255, 255, 0.6);
  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
}

.menu-item.active {
  color: #fff;
}

.sensei__table-header {
  height: 24px;
  color: rgba(255, 255, 255, 0.72);
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
}
.sensei__table-header-item {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sensei__table-body {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
}
.sensei__table-body-tr {
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sensei__table-body-tr-hover {
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 8px;
}
.sensei__table-body-tr-hover:hover {
  background-color: #1c1c1e;
}
.sensei__table-body-td {
  color: #fff;
  height: 48px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
}
.sensei__table-no-data {
  opacity: 0.72;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
}
